import { get, post, put } from "@/application/api";
import { AxiosResponse } from "axios";
import { OrderStatus } from "@/modules/salesOrder/enum/OrderStatus";
import { TermsOfDelivery } from "@/modules/salesOrder/enum/TermsOfDelivery";

export interface ConceptSalesOrderItem {
  articleNumber: string;
  quantityUnit: number;
  unit: string;
  quantitySku: number;
  name?: string;
  stock?: number;
  priceInclVat?: number;
  vatPercentage?: number;
  discountPercentage?: number;
  productTypeCode?: string;
  ean?: string;
  units?: {
    name: string;
    quantitySku: number;
  }[];
}

export interface ConceptSalesOrder {
  orderDate: string;
  deliveryDate: string;
  orderNumber: string;
  reference?: string;
  shippingMethod: string | null;
  webshopSourceId: string | null;
  shipmentCostsInclVat?: number;
  note: string;
  comment: string;
  language?: "NL" | "EN";
  termsOfDelivery?: TermsOfDelivery;
  customFields: {
    internalComment: string;
  };
  addressBookAddressId: number;
  saveInAddressBook: boolean;
  invoiceAddress: {
    companyName: string;
    department: string;
    firstName: string;
    lastName: string;
    country: string;
    postalCode: string;
    houseNumber: string;
    houseNumberAddition: string;
    isWithoutPostalCode: boolean;
    street: string;
    addressLine: string;
    city: string;
    address: string;
    email: string;
    phone: string;
  };
  useDeliveryAsInvoiceAddress: boolean;
  deliveryAddress: {
    companyName: string;
    department: string;
    firstName: string;
    lastName: string;
    country: string;
    postalCode: string;
    houseNumber: string;
    houseNumberAddition: string;
    isWithoutPostalCode: boolean;
    street: string;
    addressLine: string;
    city: string;
    address: string;
    email: string;
    phone: string;
  };
  vatNumber: string;
  items: ConceptSalesOrderItem[];
  statusId: OrderStatus;
}

export const conceptSave = ({
  salesOrder,
  clientId,
}: {
  salesOrder: ConceptSalesOrder;
  clientId: number;
}): Promise<AxiosResponse> =>
  post(`client/${clientId}/sales-order/concept`, salesOrder);

export const conceptSaveById = ({
  salesOrderId,
  salesOrder,
  clientId,
}: {
  salesOrder: ConceptSalesOrder;
  salesOrderId: number;
  clientId: number;
}): Promise<AxiosResponse> =>
  put(`client/${clientId}/sales-order/concept/${salesOrderId}`, salesOrder);

export const send = ({
  salesOrder,
  clientId,
}: {
  salesOrder: ConceptSalesOrder;
  clientId: number;
}): Promise<AxiosResponse> => {
  return post(`client/${clientId}/sales-order`, salesOrder);
};

export const sendById = ({
  salesOrderId,
  salesOrder,
  clientId,
}: {
  salesOrder: ConceptSalesOrder;
  salesOrderId: number;
  clientId: number;
}): Promise<AxiosResponse> => {
  return post(
    `client/${clientId}/sales-order/concept/${salesOrderId}`,
    salesOrder
  );
};

export async function ConceptSalesOrderShow({
  clientId,
  salesOrderId,
}: {
  clientId: number;
  salesOrderId: number;
}): Promise<
  AxiosResponse<{
    data: ConceptSalesOrder;
    isManual?: boolean;
    validationErrors: { message: string; errors: Record<string, string[]> };
  }>
> {
  const response = await get(
    `client/${clientId}/sales-order/concept/${salesOrderId}`
  );
  response.data.data.statusId = response.data.data.statusId.toString();

  return response;
}
