
import Vue from "vue";
import KForm from "@/components/KForm.vue";
import KTextField from "@/components/crud/fields/KTextField.vue";
import { AxiosResponse } from "axios";
import eventBus from "@/application/eventBus";
import KSelect from "@/components/crud/fields/KSelect.vue";
import KTextarea from "@/components/crud/fields/KTextarea.vue";
import KCheckbox from "@/components/crud/fields/KCheckbox.vue";
import {
  conceptSave,
  conceptSaveById,
  ConceptSalesOrder,
  ConceptSalesOrderItem,
  ConceptSalesOrderShow,
  send,
  sendById,
} from "@/modules/salesOrder/api/salesOrderSave";
import { mapGetters, mapMutations } from "vuex";
import RequiredClientDialog from "@/modules/client/components/RequiredClientDialog.vue";
import CountryAutocomplete from "@/modules/salesOrder/components/CountryAutocomplete.vue";
import ProductAutocomplete from "@/modules/salesOrder/components/ProductAutocomplete.vue";
import dayjs from "@/plugins/dayjs";
import { getAddressInfo } from "@/modules/salesOrder/api/addressInfo";
import KDateTimeField from "@/components/crud/fields/KDateTimeField.vue";
import { searchClientProducts } from "@/modules/salesOrder/api/product";
import shippingMethodAutocomplete from "@/modules/salesOrder/components/ShippingMethodSelect.vue";
import { TranslatedEnumOptions } from "@/application/util/enum";
import OrderStatusSelect from "@/modules/salesOrder/components/OrderStatusSelect.vue";
import { OrderStatus } from "@/modules/salesOrder/enum/OrderStatus";
import TermsOfDeliverySelect from "@/modules/salesOrder/components/TermsOfDeliverySelect.vue";
import AddressBookAutocomplete from "@/modules/salesOrder/components/AddressBookAutocomplete.vue";
import { AddressBookAddress } from "@/modules/addressbook/api/addressBookAddressSave";
import WebshopSourceAutocomplete from "@/modules/salesOrder/components/WebshopSourceAutocomplete.vue";

interface ComponentData {
  isLoading: boolean;
  isManual: boolean;
  values: ConceptSalesOrder | null;
  isLoadingSave: boolean;
  isFormDirty: boolean;
  unitOptions: TranslatedEnumOptions;
  addressBookAddress: AddressBookAddress | null;
  productToAdd: {
    articleNumber: string;
    name: string;
    stock: 0;
    productTypeCode: string;
    units: {
      name: string;
      quantitySku: number;
    }[];
  } | null;
}

export default Vue.extend({
  name: "SalesOrderForm",
  components: {
    AddressBookAutocomplete,
    TermsOfDeliverySelect,
    OrderStatusSelect,
    shippingMethodAutocomplete,
    WebshopSourceAutocomplete,
    KDateTimeField,
    ProductAutocomplete,
    CountryAutocomplete,
    RequiredClientDialog,
    KCheckbox,
    KTextarea,
    KSelect,
    KTextField,
    KForm,
  },
  data: (): ComponentData => ({
    isLoading: true,
    productToAdd: null,
    addressBookAddress: null,
    isManual: false,
    values: null,
    isFormDirty: false,
    isLoadingSave: false,
    unitOptions: [],
  }),
  created() {
    this.resetSalesForm();
  },
  computed: {
    ...mapGetters("authorisation", ["client", "isClient"]),
    isUpdateForm(): boolean {
      return this.$route.params.salesOrderId !== undefined;
    },
    useDutchAddressForInvoice(): boolean {
      if (!this.values) return true;
      return this.countryIsNLorBE(this.values.invoiceAddress.country);
    },
    useDutchAddressForDelivery(): boolean {
      if (!this.values) return true;
      return this.countryIsNLorBE(this.values?.deliveryAddress.country);
    },
    moreOrderedThenStockAvailable(): boolean {
      if (!this.values) return true;
      return this.values?.items.some(
        (item) => (item.stock || 0) < item.quantitySku
      );
    },
    isManualOrder(): boolean {
      return this.isManual || !this.isUpdateForm;
    },
    clientId(): number {
      if (this.isUpdateForm) {
        return parseInt(this.$route.params.clientId as string);
      }
      return this.client?.id;
    },
  },
  watch: {
    "$route.params.salesOrderId": {
      immediate: true,
      async handler(id: number): Promise<void> {
        if (id !== undefined) {
          try {
            this.isLoading = true;
            const response = await ConceptSalesOrderShow({
              salesOrderId: id,
              clientId: this.clientId,
            });
            if (response.data.validationErrors) {
              setTimeout(() => {
                Object.keys(response.data.validationErrors.errors).forEach(
                  (key) => {
                    this.addValidationErrorToStore({
                      key,
                      message: response.data.validationErrors.errors[key][0],
                    });
                  }
                );
                (this.$refs.form as any).$refs.form.validate();
              }, 500);
            }
            this.resetSalesForm();
            this.values = { ...this.values, ...response.data.data };
            this.fetchNewItemValues();
            this.isManual = response.data.isManual || false;
            this.$nextTick(() => {
              this.isFormDirty = false;
            });
          } catch (error: any) {
            if (error.response?.status === 404) {
              this.$router.push({ name: "notFound" });
              return;
            }
            throw error;
          }
        }
        this.isLoading = false;
      },
    },
    values: {
      deep: true,
      handler(): void {
        this.isFormDirty = true;
      },
    },
    addressBookAddress() {
      if (!this.addressBookAddress) return;
      if (this.values.saveInAddressBook) {
        if (this.addressBookAddress) {
          this.values.addressBookAddressId = this.addressBookAddress.id;
        } else {
          this.values.addressBookAddressId = null;
        }
      }

      this.values.deliveryAddress = {
        ...this.addressBookAddress.deliveryAddress,
      };

      this.values.useDeliveryAsInvoiceAddress =
        this.addressBookAddress.useDeliveryAsInvoiceAddress;

      this.values.invoiceAddress = {
        ...this.addressBookAddress.invoiceAddress,
      };
    },
    "values.saveInAddressBook"() {
      if (this.values.saveInAddressBook) {
        if (this.addressBookAddress) {
          this.values.addressBookAddressId = this.addressBookAddress.id;
        } else {
          this.values.addressBookAddressId = null;
        }
      }
    },
    "values.useDeliveryAsInvoiceAddress"() {
      if (!this.values) return true;
      if (this.values.useDeliveryAsInvoiceAddress) {
        this.values.invoiceAddress = {
          ...this.values.deliveryAddress,
        };
      }
    },
    "client.id": {
      handler(newValue: number, oldValue: number): void {
        if (!oldValue) return;
        this.$router.push({
          name: "salesOrder.concept",
        });
      },
    },
    "values.deliveryAddress": {
      handler() {
        if (!this.values) return;
        if (this.values.useDeliveryAsInvoiceAddress) {
          this.values.invoiceAddress = {
            ...this.values.deliveryAddress,
          };
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations("error", { addValidationErrorToStore: "add" }),
    send(): void | Promise<AxiosResponse<ConceptSalesOrder>> {
      if (!this.values) {
        return;
      }
      this.isFormDirty = false;
      if (this.isUpdateForm) {
        return this.updateFormSend();
      }
      return this.createFormSend();
    },
    redirectToNextBulk() {
      if (
        !(this.$route.query.salesOrderIds as string[]) ||
        !(this.$route.query.clientIds as string[])
      ) {
        return;
      }

      const redirectFlow = () => {
        const index = this.$route.query.salesOrderIds.indexOf(
          this.$route.params.salesOrderId
        );
        if (
          index === this.$route.query.salesOrderIds.length - 1 ||
          !this.$route.query.salesOrderIds[index + 1]
        ) {
          this.$router.push({
            name: "salesOrder.concept",
            query: { bulkFinished: "true" },
          });
          return;
        }
        this.$router.push({
          name: "salesOrder.edit",
          params: {
            salesOrderId: this.$route.query.salesOrderIds[index + 1] as string,
            clientId: this.$route.query.clientIds[index + 1] as string,
          },
          query: {
            salesOrderIds: this.$route.query.salesOrderIds,
            clientIds: this.$route.query.clientIds,
          },
        });
      };

      if (!this.isFormDirty) {
        redirectFlow();
        return;
      }
      eventBus.$emit("confirm", {
        title: this.$t("global.confirmationTitle"),
        body: this.$t("global.confirmationContinueWithoutSaving"),
        confirmCallback: async () => {
          redirectFlow();
        },
      });
    },
    createFormSend(): Promise<AxiosResponse> {
      return send({
        salesOrder: this.values,
        clientId: this.clientId,
      });
    },
    updateFormSend(): Promise<AxiosResponse> {
      return sendById({
        salesOrder: this.values,
        salesOrderId: parseInt(this.$route.params.salesOrderId),
        clientId: this.clientId,
      });
    },
    async saveConcept() {
      this.isLoadingSave = true;
      try {
        if (!this.values) {
          return;
        }
        await this.ifUpdateFormSaveConcept();
        await this.ifCreateFormSaveConcept();
        eventBus.$emit("snackbar", this.$t("global.saveSuccess"));
        this.isFormDirty = false;
        this.redirectToTableIfNNotBulk();
      } catch (e) {
        eventBus.$emit("snackbar", {
          color: "error",
          text: this.$t("global.error"),
        });
        throw e;
      }
      this.isLoadingSave = false;
    },
    async ifUpdateFormSaveConcept() {
      if (!this.values) return;
      if (this.isUpdateForm) {
        await conceptSaveById({
          salesOrder: this.values,
          clientId: this.clientId,
          salesOrderId: parseInt(this.$route.params.salesOrderId),
        });
      }
    },
    async ifCreateFormSaveConcept() {
      if (!this.values) return;
      if (!this.isUpdateForm) {
        await conceptSave({
          salesOrder: this.values,
          clientId: this.clientId,
        });
      }
    },
    handleSuccess() {
      eventBus.$emit("snackbar", this.$t("salesOrder.messages.sendSuccess"));
      this.redirectToNextBulk();
      this.redirectToTableIfNNotBulk();
    },
    addProduct() {
      this.values.items.push({
        articleNumber: this.productToAdd?.articleNumber || "",
        quantityUnit: 1,
        unit: this.productToAdd?.units?.[0].name || "",
        quantitySku: this.productToAdd?.units?.[0].quantitySku || 1,
        name: this.productToAdd?.name,
        stock: this.productToAdd?.stock,
        priceInclVat: 0,
        vatPercentage: 0,
        discountPercentage: 0,
        productTypeCode: this.productToAdd?.productTypeCode,
        units: this.productToAdd?.units,
      });
      this.productToAdd = null;
    },
    removeProduct(index: number) {
      if (!this.values) return;
      this.values.items.splice(index, 1);
    },
    countryIsNLorBE(country: string): boolean {
      return ["NL", "BE"].includes(country);
    },
    updateTotalItems(item: ConceptSalesOrderItem) {
      if (!item.units) item.quantitySku = item.quantityUnit;
      const selectedUnit = item?.units?.find((unit) => unit.name === item.unit);
      if (!selectedUnit) return;

      item.quantitySku = selectedUnit.quantitySku * item.quantityUnit;
    },
    async fillAddressByPostalCode(
      parent:
        | ConceptSalesOrder["invoiceAddress"]
        | ConceptSalesOrder["deliveryAddress"]
    ) {
      try {
        const { postalCode, houseNumber, houseNumberAddition } = parent;
        const response = await getAddressInfo({
          postalCode,
          houseNumber,
          houseNumberAddition,
        });
        parent.city = response.data.data.city;
        parent.street = response.data.data.street;
      } catch (e) {
        return;
      }
    },
    async fetchNewItemValues() {
      if (!this.values) return;

      const response = await searchClientProducts(this.clientId, {
        items: this.values.items.map((item) => ({
          articleNumber: item.articleNumber,
          ean: item.ean,
        })),
      });

      (this.values as ConceptSalesOrder).items.forEach((item, index) => {
        const searchResult = response.data.data.find(
          (search) =>
            (!!item.articleNumber &&
              search.articleNumber === item.articleNumber) ||
            (!item.articleNumber && item.ean && search.ean === item.ean)
        );

        this.$set((this.values as ConceptSalesOrder).items, index, {
          ...(this.values as ConceptSalesOrder).items[index],
          ...searchResult,
        });
      });
    },
    redirectToTableIfNNotBulk() {
      if (!(this.$route.query.salesOrderIds as string[])) {
        this.$router.push({
          name: "salesOrder.concept",
        });
      }
    },
    resetSalesForm() {
      this.values = {
        orderDate: dayjs().format("YYYY-MM-DDThh:mm:ss"),
        deliveryDate: dayjs().format("YYYY-MM-DD"),
        orderNumber: dayjs().format("YYMMDDhhmmss"),
        shippingMethod: null,
        webshopSourceId: null,
        termsOfDelivery: undefined,
        shipmentCostsInclVat: 0,
        note: "",
        comment: "",
        customFields: { internalComment: "" },
        vatNumber: "",
        statusId: OrderStatus.DataIssue,
        addressBookAddressId: 0,
        saveInAddressBook: false,
        invoiceAddress: {
          companyName: "",
          department: "",
          firstName: "",
          lastName: "",
          country: "",
          postalCode: "",
          houseNumber: "",
          houseNumberAddition: "",
          isWithoutPostalCode: false,
          street: "",
          addressLine: "",
          city: "",
          address: "",
          email: "",
          phone: "",
        },
        useDeliveryAsInvoiceAddress: true,
        deliveryAddress: {
          companyName: "",
          department: "",
          firstName: "",
          lastName: "",
          country: "",
          postalCode: "",
          houseNumber: "",
          houseNumberAddition: "",
          isWithoutPostalCode: false,
          street: "",
          addressLine: "",
          city: "",
          address: "",
          email: "",
          phone: "",
        },
        items: [],
      };
    },
  },
});
