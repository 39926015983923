import { get } from "@/application/api";
import { AxiosResponse } from "axios";

export default (
  clientId: number
): Promise<
  AxiosResponse<{
    data: { name: string; code: string }[];
  }>
> => get(`client/${clientId}/webshop-source`);
